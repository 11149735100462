
import { Options, Vue } from 'vue-class-component';
import { GarmentClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class FootwearDetail extends Vue {

    model: OM.FootwearConfiguration = new OM.FootwearConfiguration();
    footwearIdentifier: string = "";

    created() {
        this.footwearIdentifier = this.$route.params.identifier.toString();
        this.init();
    }

    init(){
        GarmentClient.getFootwearConfiguration(this.footwearIdentifier)
        .then(x => {
            this.model = x;
        })
    }

}
